import {
  gateReducer,
  popupQuoteReducer,
  popupReducer,
  tableReducer,
} from './slices';

import { configureStore } from '@reduxjs/toolkit';

export default configureStore({
  reducer: {
    popup: popupReducer,
    gate: gateReducer,
    popupQuote: popupQuoteReducer,
    table: tableReducer,
  },
});
