import { createSlice } from '@reduxjs/toolkit';

export const popupSlice = createSlice({
  name: 'popup',
  initialState: {
    show: false,
  },
  reducers: {
    setTrue: (state) => {
      state.show = true;
    },
    setFalse: (state) => {
      state.show = false;
    },
    toggle: (state) => {
      state.show = !state;
    },
  },
});

export const popupQuoteSlice = createSlice({
  name: 'popupQuote',
  initialState: {
    show: false,
  },
  reducers: {
    setQuoteTrue: (state) => {
      state.show = true;
    },
    setQuoteFalse: (state) => {
      state.show = false;
    },
    toggleQuote: (state) => {
      state.show = !state;
    },
  },
});

export const gateSlice = createSlice({
  name: 'gate',
  initialState: {
    authorized: false,
  },
  reducers: {
    setAuthorize: (state) => {
      state.authorized = true;
    },
    setUnauthorize: (state) => {
      state.authorized = false;
    },
  },
});

export const tableSlice = createSlice({
  name: 'table',
  initialState: {
    filter: [],
    sort: [],
  },
  reducers: {
    setTableFilter: (state, action) => {
      const filterArr = action.payload;
      const newState = [...state.filter];

      filterArr.forEach((el) => {
        const existingFilter = newState.findIndex((e) => e.id === el.id);
        if (existingFilter !== -1) {
          newState[existingFilter].value = el.value;
        } else newState.push(el);
      });
      state.filter = newState;
    },
    resetTableFilter: (state) => {
      state.filter = [];
    },
    setTableSort: () => {},
  },
});

//////////////////////////
export const popupReducer = popupSlice.reducer;
export const { setTrue, setFalse, toggle } = popupSlice.actions;
export const showPopup = (state) => state.popup.show;

//////////////////////////
export const gateReducer = gateSlice.reducer;
export const { setAuthorize, setUnauthorize } = gateSlice.actions;
export const gateState = (state) => state.gate.authorized;

//////////////////////////

export const popupQuoteReducer = popupQuoteSlice.reducer;
export const { setQuoteTrue, setQuoteFalse, toggleQuote } =
  popupQuoteSlice.actions;
export const showPopupQuote = (state) => state.popupQuote.show;

//////////////////////////
export const tableReducer = tableSlice.reducer;
export const { resetTableFilter, setTableFilter, setTableSort } =
  tableSlice.actions;
export const tableFilter = (state) => state.table.filter;
