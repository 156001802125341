import './src/styles/global.css';

import { Provider } from 'react-redux';
import React from 'react';
import disableScroll from 'disable-scroll';
import store from './src/state/store';

export const onRouteUpdate = ({ location, prevLocation }) => {
  disableScroll.off();
};


export const wrapRootElement = ({ element }) => {
  return <Provider store={store}>{element}</Provider>;
};
